import React from 'react'
import styled, { keyframes } from 'styled-components'
import { StyledContainer } from '../pages/index'
import Description from './Description.js'
import Chevron from './svg/Chevron'
import { seaBlue } from './variables'
import bg from '../data/images/bg-min.jpg'
import bgMobile from '../data/images/bgMobile-min.jpg'
import bgSmartphone from '../data/images/bgSmartphone-min.jpg'
import bgSmartphone640h from '../data/images/bgSmartphone640h-min.jpg'

const Opening = ({
  locationId,
  propertyName,
  openingSubtitle,
  seeMoreButton,
  descriptionInfo,
  descriptionInfoStatic,
  zeroOffset,
  secondDescriptionInfo,
  secondDescriptionInfoStatic,
  lang,
}) => {
  const scrollToSeeMoreHandler = e => {
    e.preventDefault()
    const location = document.getElementById('DESCRIPTION').offsetTop - 81
    window.scrollTo({ top: location, left: 0, behavior: 'smooth' })
  }

  return (
    <StyledSection id={'OPENING'}>
      <StyledContainer>
        <StyledHeadersContainer>
          <StyledHeader>{propertyName}</StyledHeader>
          <StyledSubtitleDiv>
            <StyledPropertySubtitle>{openingSubtitle}</StyledPropertySubtitle>
          </StyledSubtitleDiv>
          <StyledFlexContainer>
            <StyledButton onClick={scrollToSeeMoreHandler} href="/">
              {seeMoreButton}
              <div>
                <Chevron ovalSize="40px" arrowSize="18px" />
              </div>
            </StyledButton>
          </StyledFlexContainer>
        </StyledHeadersContainer>
        <StyledHeadersContainer>
          <StyledHeader>{propertyName}</StyledHeader>
          <StyledSubtitleDiv>
            <StyledPropertySubtitle>{openingSubtitle}</StyledPropertySubtitle>
          </StyledSubtitleDiv>
          <StyledFlexContainer>
            <StyledButton onClick={scrollToSeeMoreHandler} href="/">
              {seeMoreButton}
              <div>
                <Chevron ovalSize="40px" arrowSize="18px" />
              </div>
            </StyledButton>
          </StyledFlexContainer>
        </StyledHeadersContainer>
      </StyledContainer>
      <StyledBackgroundImage lang={lang} />
      <DescriptionContainer>
        <Description
          descriptionInfoStatic={descriptionInfoStatic}
          descriptionInfo={descriptionInfo}
          zeroOffset={zeroOffset}
          locationId={locationId}
        />
        <Description
          descriptionInfoStatic={secondDescriptionInfoStatic}
          descriptionInfo={secondDescriptionInfo}
          zeroOffset={zeroOffset}
          locationId={locationId}
          reversed
        />
      </DescriptionContainer>
    </StyledSection>
  )
}

export default React.memo(Opening)

const DescriptionContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;

  @media screen and (max-width: 768px) {
    position: static;
  }
`

const StyledBackgroundImage = styled.div`
  height: calc(100vh + ${p => (p.lang === 'EN' ? '600px' : '1000px')});
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%), url(${bg});
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 1200px) {
    height: calc(100vh + 1200px);
  }

  @media screen and (max-width: 992px) {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%), url(${bgMobile});
  }

  @media screen and (max-width: 768px) {
    height: calc(100vh - 81px);
  }

  @media (max-width: 576px) and (min-height: 801px) {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%), url(${bg});
  }
  @media (max-width: 576px) and (max-height: 800px) {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%), url(${bgSmartphone});
  }
`

const StyledSection = styled.section`
  max-height: calc(100vh + 1200px);
  position: relative;

  /* calc value 558 = description section height, 81 = padding top opening section (header height)  */
  background: #000;
  padding-top: 0px;

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 81px);
    max-height: none;
  }
`
const StyledHeader = styled.h1`
  font-family: OpenSans-SemiBold;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 3.39;
  letter-spacing: 3.27px;
  color: #fff;
  margin-bottom: 5px;
`

const StyledPropertySubtitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 55px;
  font-weight: 600;
  color: #fff;
  line-height: 1.36;
  letter-spacing: 3px;
  @media screen and (max-width: 768px) {
    font-size: 38px;
  }
  @media screen and (max-width: 576px) {
    font-size: 27px;
  }
`

const StyledSubtitleDiv = styled.div`
  width: 719px;
  border-radius: 12px;
  border: solid 3px #ffffff;
  padding: 6px 35px 17px 35px;
  margin: 0 0 25px -39px;
  @media screen and (max-width: 1200px) {
    margin: 0 0 25px 0;
  }
  @media screen and (max-width: 768px) {
    font-size: 40px;
    width: 90%;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    font-size: 30px;
    padding: 6px 25px 17px 25px;
  }
`

const StyledHeadersContainer = styled.div`
  width: 720px;
  height: 300px;
  z-index: 3;
  position: absolute;
  top: min(calc(50vh - 200px), 222px);

  @media screen and (max-width: 768px) {
    width: auto;
    padding: 0 10px;
    left: 0;
    top: 22px;
  }
`

export const chevronAnimation = keyframes`
0% { transform : translateX(0); }
50% { transform : translateX(10px); }
100% { transform : translateX(0); }
`
export const StyledButton = styled.a`
  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  margin-right: 50px;

  @media screen and (max-width: 576px) {
    margin-bottom: 20px;
  }
  div {
    margin-left: 15px;
  }
  &:hover {
    div {
      @media screen and (min-width: 992px) {
        animation: ${chevronAnimation} 0.6s ease-in-out infinite;
      }
    }
  }
`

const StyledFlexContainer = styled.div`
  display: flex;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`
export const StyledSeparator = styled.div`
  height: 10px;
  background: ${seaBlue};
`
