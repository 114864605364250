import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const schemaData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Atrium Katowice',
  alternateName: 'Atrium Katowice - Powierzchnia do wynajęcia',
  url: 'https://www.m7logistics.pl/',
  logo: 'https://www.m7logistics.pl/static/bg-0b6c18c3832be5eb102e6d4148944dea.jpg',
  sameAs: 'https://uk.linkedin.com/company/martley-capital-group/',
}

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
      }

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <html lang="pl" />
            <title>{seo.title}</title>
            <meta name="theme-color" content="#000"></meta>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <script type="application/ld+json">
              {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'LocalBusiness',
                name: 'M7 Logistics',
                image: 'https://www.m7logistics.pl/static/bg2-min-665c43699a4422ff4a70e00728713b41.jpg',
                '@id': '',
                priceRange: '',
                url: 'https://www.m7logistics.pl/',
                description: 'Nowoczesne obiekty magazynowo-biurowe ➤ Sprawdź!',
                disambiguatingDescription:
                  'M7 Logistics Sosnowiec to nowoczesny obiekt biurowo-magazynowy wybudowany w 2014 roku, zlokalizowany przy ul. Baczyńskiego w Sosnowcu. Obiekt jest położony 1,2 km od drogi S86. Ma bardzo dobre połączenie z centrum Sosnowca oraz wszystkimi miastami aglomeracji śląskiej. Dla pracowników i klientów udogodnieniem są duże parkingi dla samochodów osobowych oraz dostęp do komunikacji miejskiej dzięki przystankowi autobusowemu oraz tramwajowemu w bezpośrednim sąsiedztwie nieruchomości. ',
                address: {
                  '@type': 'PostalAddress',
                  addressLocality: 'Sosnowiec',
                  addressRegion: 'Województwo śląskie',
                  postalCode: '41-203',
                  streetAddress: 'Krzysztofa Kamila Baczyńskiego 25E',
                  addressCountry: 'PL',
                  telephone: '+48 693 330 456',
                  email: 'karolina.bialkowska@cbre.com',
                },
                openingHoursSpecification: {
                  '@type': 'OpeningHoursSpecification',
                  dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                  opens: '08:00',
                  closes: '18:00',
                },
                sameAs: ['https://m7logistics.pl/'],
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'LocalBusiness',
                name: 'M7 Logistics',
                image: 'https://www.m7logistics.pl/static/bg2-min-665c43699a4422ff4a70e00728713b41.jpg',
                '@id': '',
                priceRange: '',
                url: 'https://www.m7logistics.pl/',
                description: 'Nowoczesne obiekty magazynowo-biurowe ➤ Sprawdź!',
                disambiguatingDescription:
                  'M7 Logistics Siemianowice to obiekt magazynowy klasy A zlokalizowany bezpośrednio przy drodze krajowej nr 94, na terenie Siemianowickiego Parku Biznesowego, z doskonałym dojazdem do autostrady A1 oraz dróg ekspresowych S86 i S1 zapewniających wygodną komunikację w rejonie Górnego Śląska. Dodatkowym atutem lokalizacji jest bliskość centrum Katowic oraz dobra organizacja transportu miejskiego zapewniająca dogodny dojazd dla pracowników. Park Siemianowice Śląskie to projekt magazynowy o łącznej powierzchni około 12 700 mkw. Obiekt charakteryzuje się wysoką jakością oraz zapewnia rozwiązania energooszczędne takie jak oświetlenie LED oraz wysoki standard ochrony przeciwpożarowej. Budynek wyposażony jest w komfortowe place manewrowe i drogę o szerokości 7 metrów, co daje łączną głębokość na poziomie 35 metrów.',
                address: {
                  '@type': 'PostalAddress',
                  addressLocality: 'Siemianowice Śląskie',
                  addressRegion: 'Województwo śląskie',
                  postalCode: '41-100',
                  streetAddress: 'Księdza Jana Frenzla 5',
                  addressCountry: 'PL',
                  telephone: '+48 693 330 456',
                  email: 'karolina.bialkowska@cbre.com',
                },
                openingHoursSpecification: {
                  '@type': 'OpeningHoursSpecification',
                  dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                  opens: '08:00',
                  closes: '18:00',
                },
                sameAs: ['https://m7logistics.pl/'],
              })}
            </script>
            <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
