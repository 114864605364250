import React from "react"

const Floor = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.49 40.49">
      <g data-name="Warstwa 2">
        <g data-name="Warstwa 1">
          <path
            d="M40.09,23.23a.4.4,0,0,0,0-.8H37.67V3.63h2.42a.4.4,0,0,0,0-.8H37.67V.4a.41.41,0,0,0-.81,0V2.83H23.23V.4a.4.4,0,1,0-.8,0V2.83H18.06V.4a.4.4,0,1,0-.8,0V2.83H8.79V.4a.39.39,0,0,0-.4-.4A.4.4,0,0,0,8,.4V2.83H3.63V.4a.4.4,0,1,0-.8,0V2.83H.4a.4.4,0,0,0,0,.8H2.83V8H.4a.4.4,0,1,0,0,.8H2.83v8.47H.4a.4.4,0,1,0,0,.8H2.83v4.37H.4a.4.4,0,0,0,0,.8H2.83V36.86H.4a.4.4,0,1,0,0,.8H2.83v2.43a.4.4,0,0,0,.8,0V37.66H17.26v2.43a.4.4,0,0,0,.8,0V37.66h4.37v2.43a.4.4,0,0,0,.8,0V37.66H31.7v2.43a.4.4,0,0,0,.8,0V37.66h4.36v2.43a.41.41,0,0,0,.81,0V37.66h2.42a.4.4,0,1,0,0-.8H37.67V32.5h2.42a.4.4,0,1,0,0-.8H37.67V23.23Zm-22,0h4.37V31.7H18.06Zm4.37-6H18.06V8.79h4.37Zm-5.17,0H8.79V8.79h8.47Zm0,.8v4.37H8.79V18.06Zm.8,0h4.37v4.37H18.06Zm5.17,5.17H31.7V31.7H23.23Zm0-.8V18.06H31.7v4.37Zm9.27-4.37h4.36v4.37H32.5ZM36.86,3.63V17.26H23.23V3.63Zm-14.43,0V8H18.06V3.63Zm-5.17,0V8H8.79V3.63Zm-13.63,0H8V8H3.63Zm0,5.16H8v8.47H3.63Zm0,9.27H8v4.37H3.63Zm0,18.8V23.23H17.26V36.86Zm14.43,0V32.5h4.37v4.36Zm5.17,0V32.5H31.7v4.36Zm13.63,0H32.5V32.5h4.36Zm0-5.16H32.5V23.23h4.36Z"
            fill="#010101"
          />
        </g>
      </g>
    </svg>
  )
}

export default Floor
