import React from 'react'
import styled, { keyframes } from 'styled-components'
import Chevron from './svg/Chevron'
import Arrow from './svg/Arrow'
import { seaBlue } from './variables'

const Description = ({ descriptionInfo, descriptionInfoStatic, zeroOffset, locationId, reversed = false }) => {
  const goToLocationHandler = e => {
    e.preventDefault()
    const location = document.getElementById(locationId).offsetTop - 81
    window.scrollTo({ top: location, left: 0, behavior: 'smooth' })
  }

  const goToPortfolioHandler = e => {
    e.preventDefault()
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <StyledDescriptionWrapper id="DESCRIPTION">
      <ContentContainer reversed={reversed}>
        <DescriptionContainer reversed={reversed}>
          <StyledDescription>{descriptionInfo.propertyDecription}</StyledDescription>
          <StyledLink onClick={goToLocationHandler}>
            <StyledLinkText>{descriptionInfo.locationButtonLabel}</StyledLinkText>
            <Chevron arrowSize="20px" ovalSize="40px" backgroundColor="#ff5722" />
          </StyledLink>
        </DescriptionContainer>
        <StyledBoxesWrapper>
          <StyledBox>
            <BoxTitle>{descriptionInfo.boxLabel}</BoxTitle>
            <BoxMeters wide>
              {descriptionInfoStatic.totalLeaseArea}
              {descriptionInfo.metricUnit}
            </BoxMeters>
            <BoxSubtitle>{descriptionInfo.firstBoxSublabel}</BoxSubtitle>
          </StyledBox>
          <StyledBox>
            <BoxTitle>{descriptionInfo.boxLabel}</BoxTitle>
            <BoxMeters>
              {descriptionInfoStatic.floorLeaseArea}
              {descriptionInfo.metricUnit}
            </BoxMeters>
            <BoxSubtitle>{descriptionInfo.secondBoxSublabel}</BoxSubtitle>
            <StyledButton href="/" onClick={goToPortfolioHandler}>
              {descriptionInfo.portfolioButton}
              <div>
                <Arrow color="#fff" arrowSize="18px" />
              </div>
            </StyledButton>
          </StyledBox>
        </StyledBoxesWrapper>
      </ContentContainer>
      <StyledScrollDown zeroOffset={zeroOffset}>
        <ScrollDownText>Scroll down</ScrollDownText>
      </StyledScrollDown>
    </StyledDescriptionWrapper>
  )
}
export default Description

const chevronAnimation = keyframes`
0% { margin-left : 15px; }
50% { margin-left : 25px; }
100% { margin-left : 15px; }
`

const StyledDescriptionWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 45, 115, 0.82);
  z-index: 3;
  position: relative;
  /* bottom: 1116px; */
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 20px;

  @media screen and (max-width: 950px) {
    padding-top: 20px;
  }

  @media screen and (max-width: 768px) {
    background-color: rgba(0, 45, 115);
    height: auto;
    top: 0;
    position: relative;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 576px) {
    height: auto;
    top: 0;
    position: relative;
    background-color: rgba(0, 45, 115);
  }
`

export const StyledButton = styled.a`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  text-align: left;
  position: absolute;
  bottom: -70px;
  right: 0;
  padding: 10px 10px;
  max-width: 100%;

  @media screen and (max-width: 1370px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    bottom: -50px;
    right: 10px;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    bottom: -40px;
    right: 0;
  }
  @media screen and (max-width: 576px) {
    position: relative;
  }
  div {
    margin-left: 15px;
  }
`

const StyledBoxesWrapper = styled.div`
  position: relative;
  flex: 3;
  display: flex;
  grid-gap: 20px;

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`
const ContentContainer = styled.div`
  max-width: 1190px;
  grid-gap: 50px;
  height: 100%;
  left: calc(50% - 580px);
  display: flex;
  justify-content: space-between;
  flex-direction: ${p => (p.reversed ? 'row-reverse' : 'row')};
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;

  @media only screen and (max-width: 767.99px) {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
    flex-direction: column;
  }
  @media only screen and (max-width: 1023.99px) and (min-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
  }
  @media only screen and (max-width: 1365.99px) and (min-width: 1025px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 7;
  @media screen and (max-width: 1366px) {
    margin-right: 0;
  }
`

const StyledDescription = styled.div`
  max-width: 450px;

  font-size: 18px;
  font-weight: 500;

  line-height: 1.5;
  color: #ffffff;

  margin-bottom: 20px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    margin-top: 40px;
  }
`

const StyledLink = styled.a`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  .oval {
    margin-left: 15px;
    cursor: pointer;
    pointer-events: none;
  }
  &:hover {
    .oval {
      @media screen and (min-width: 992px) {
        animation: ${chevronAnimation} 0.6s ease-in-out infinite;
      }
    }
  }
`

const StyledLinkText = styled.div`
  width: 102px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ffffff;
`

const StyledBox = styled.div`
  width: 312px;
  height: 252px;
  border-radius: 12px;
  border: solid 1px #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1366px) {
    margin-left: 40px;
    width: 290px;
  }
  @media screen and (max-width: 1024px) {
    margin: 0 10px;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100%;
  }
  @media screen and (max-width: 576px) {
    margin: 10px 0;
    height: 200px;
  }
`

const BoxTitle = styled.p`
  width: 163px;
  height: 34px;
  opacity: 0.75;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.7;
  letter-spacing: 2px;
  text-align: center;
  color: #ffffff;
  margin-top: 33px;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    font-size: 18px;
  }
`

const BoxMeters = styled.p`
  width: ${props => (props.wide ? '100%' : '254px')};
  height: 67px;
  font-size: 45px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: 0.79px;
  color: ${seaBlue};
  margin-top: 27px;
  text-align: center;
  @media screen and (max-width: 1366px) {
    font-size: 40px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    font-size: 32px;
    height: auto;
  }
`

const BoxSubtitle = styled.p`
  width: 202px;
  height: 34px;
  opacity: 0.75;

  font-size: 20px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: 2px;
  color: #ffffff;
  margin-top: 19px;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 18px;
  }
`

const StyledScrollDown = styled.div`
  opacity: ${props => (props.zeroOffset ? 1 : 0)};
  transition: 0.3s ease;
  width: 110px;
  height: 166px;
  position: absolute;
  border: solid white;
  border-width: 0 0 0 2px;
  top: -83px;
  right: calc(50% - 580px);
  @media screen and (max-width: 1266px) {
    right: 20px;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const ScrollDownText = styled.p`
  position: absolute;
  top: 30px;
  left: 15px;
  width: auto;
  height: 15px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.23px;
  text-align: center;
  color: #ffffff;
`
