import React from 'react'

import Windy from './svg/Windy'
import Klimatyzacja from './svg/Klimatyzacja'
import Floor from './svg/Floor'
import Ceiling from './svg/Ceiling'
import Sprinklers from './svg/Sprinklers'
import Detectors from './svg/Detectors'
import Optics from './svg/Optics'
import Windows from './svg/Windows'
import FloorBox from './svg/FloorBox'
import Walls from './svg/Walls'
import Reception from './svg/Reception'
import BMS from './svg/BMS'
import Access from './svg/Access'
import Security from './svg/Security'
import Accesibility from './svg/Accesibility'
import Cyclists from './svg/Cyclists'
import BREEAM from './svg/BREEAM'
import ATM from './svg/ATM'
import ElectricCables from './svg/ElectricCables'
import Teletech from './svg/Teletech'
import Cctv from './svg/Cctv'
import HVAC from './svg/HVAC'
import PPOZ from './svg/PPOZ'

export const primary = 'rgba(189, 195, 199, 1)'
export const success = '#ff5722'
export const fontColor = '#002d73'
export const seaBlue = '#00acc1'

export const icons = [
  <ElectricCables />,
  <Teletech />,
  <Cctv />,
  <HVAC />,
  <PPOZ />,
  <Klimatyzacja />,
  <Windows />,
  <Security />,
  <Detectors />,
  <Ceiling />,
  <Floor />,
  <Floor />,
  <Floor />,
  <Floor />,
  <Floor />,
  <Floor />,
  <Floor />,
  <Floor />,
]
