import React from 'react'
import styled from 'styled-components'
import Map from './Map'
import { fontColor } from './variables'
import tram from '../data/svg/tram.svg'
import train from '../data/svg/train.svg'
import gallery from '../data/svg/gallery.svg'
import bus from '../data/svg/bus.svg'
import airport from '../data/svg/airport.svg'

const Location = ({ zeroOffset, propertyName, data, mapData, transportTime, transportIconLoc, lang }) => {
  return (
    <StyledSection id={`${data.sectionTitle.toUpperCase()}`}>
      {!zeroOffset ? (
        <div>
          <StyledMapContainer id="mapContainer">
            <Map propertyName={propertyName} mapData={mapData} lat={mapData.lat} lng={mapData.lng} transportIconLoc={transportIconLoc} />
          </StyledMapContainer>
          <StyledMapDescriptionWrapper>
            <StyledInnerWrapper>
              <StyledTransportDiv>
                <StyledTransportHeader>{data.addressLabel}</StyledTransportHeader>
                <StyledAdress href={data.adressUrl} target="_blank">
                  {data.adress}
                </StyledAdress>
                <StyledParagraph>
                  {data.centerDistance}
                  <StyledSpan>{transportTime.timeFromCenter}</StyledSpan>
                </StyledParagraph>
                <StyledFlexDiv>
                  <div>
                    <StyledTransportHeader>{data.onFootLabel}</StyledTransportHeader>
                    <StyledCell>
                      <StyledIcon src={bus} alt={data.busLabel} />
                      <StyledParagraph>
                        {data.busLabel}
                        <StyledSpan>{transportTime.busTransportTime}</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    <StyledCell>
                      <StyledIcon src={tram} alt={data.tramLabel} />
                      <StyledParagraph>
                        {data.tramLabel}
                        <StyledSpan>{transportTime.tramTransportTime}</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    <StyledCell>
                      <StyledParagraph>
                        <StyledIcon src={gallery} alt={data.galleryLabel} />
                        {data.galleryLabel}
                        <StyledSpan>{transportTime.galleryTransportTimeOnFoot}</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                  </div>
                  <div>
                    <StyledTransportHeader>{data.byCarLabel}</StyledTransportHeader>

                    <StyledCell>
                      <StyledIcon src={train} alt={data.trainstationLabel} />
                      <StyledParagraph>
                        {data.trainstationLabel}
                        <StyledSpan>{transportTime.trainstationTransportTime}</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    <StyledCell>
                      <StyledIcon src={airport} alt={data.airportLabel} />
                      <StyledParagraph>
                        {data.airportLabel}
                        <StyledSpan>{transportTime.airportTransportTime}</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    <StyledCell>
                      <StyledParagraph>
                        <StyledIcon src={gallery} alt={data.galleryLabel} />
                        {data.galleryLabel}
                        <StyledSpan>{transportTime.galleryTransportTimeByCar}</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                  </div>
                </StyledFlexDiv>
              </StyledTransportDiv>

              <StyledTransportDiv>
                <StyledTransportHeader>{data.addressLabel}</StyledTransportHeader>
                <StyledAdress
                  href="https://www.google.com/maps/place/Ksi%C4%99dza+Jana+Frenzla+5,+41-946+Piekary+%C5%9Al%C4%85skie/@50.333139,18.9906135,17z/data=!3m1!4b1!4m5!3m4!1s0x4716d3c9fbe2eb17:0x7c0854114872caa0!8m2!3d50.3331356!4d18.9928022"
                  target="_blank"
                >
                  {lang === 'PL' ? 'ul. Księdza Jana Frenzla 5, Piekary Śląskie' : '5 Księdza Jana Frenzla Street / Piekary Śląskie'}
                </StyledAdress>
                <StyledParagraph>
                  {data.centerDistance}
                  <StyledSpan>14 min</StyledSpan>
                </StyledParagraph>
                <StyledFlexDiv>
                  <div>
                    <StyledTransportHeader>{data.onFootLabel}</StyledTransportHeader>
                    <StyledCell>
                      <StyledIcon src={bus} alt={data.busLabel} />
                      <StyledParagraph>
                        {data.busLabel}
                        <StyledSpan>36 min.</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    <StyledCell>
                      <StyledIcon src={tram} alt={data.tramLabel} />
                      <StyledParagraph>
                        {data.tramLabel}
                        <StyledSpan>1h 16 min.</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    <StyledCell>
                      <StyledParagraph>
                        <StyledIcon src={gallery} alt={data.galleryLabel} />
                        {data.galleryLabel}
                        <StyledSpan>1h 5 min.</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                  </div>
                  <div>
                    <StyledTransportHeader>{data.byCarLabel}</StyledTransportHeader>

                    <StyledCell>
                      <StyledIcon src={train} alt={data.trainstationLabel} />
                      <StyledParagraph>
                        {data.trainstationLabel}
                        <StyledSpan>30 min.</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    <StyledCell>
                      <StyledIcon src={airport} alt={data.airportLabel} />
                      <StyledParagraph>
                        {data.airportLabel}
                        <StyledSpan>30 min.</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    <StyledCell>
                      <StyledParagraph>
                        <StyledIcon src={gallery} alt={data.galleryLabel} />
                        {data.galleryLabel}
                        <StyledSpan>9 min.</StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                  </div>
                </StyledFlexDiv>
              </StyledTransportDiv>
            </StyledInnerWrapper>
          </StyledMapDescriptionWrapper>
        </div>
      ) : null}
    </StyledSection>
  )
}
export default Location

const StyledIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`
const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (max-width: 610px) {
    flex-direction: column;
  }
`

const StyledParagraph = styled.p`
  color: ${fontColor};
  font-family: OpenSans;
  font-size: 18px;
  @media screen and (max-width: 610px) {
    font-size: 15px;
  }
`

const StyledAdress = styled.a`
  text-decoration: none;
  font-family: OpenSans-SemiBold;
  color: ${fontColor};
  font-size: 18px;
  margin-bottom: 20px;
  @media screen and (max-width: 610px) {
    margin-bottom: 15px;
    margin-top: 5px;
    font-size: 16px;
  }
`
const StyledSpan = styled.span`
  margin-left: 10px;
  font-family: OpenSans-SemiBold;
  color: ${fontColor};
  font-size: 18px;
  @media screen and (max-width: 610px) {
    font-size: 15px;
  }
`
const StyledTransportHeader = styled.h4`
  color: ${fontColor};
  font-family: OpenSans-SemiBold;
  font-size: 18px;
  margin-top: 18px;
  @media screen and (max-width: 610px) {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`
const StyledCell = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`

const StyledTransportDiv = styled.div`
  z-index: 4;
  background: rgba(255, 255, 255, 0.7);
  padding: 20px;
  max-width: 350px;

  @media screen and (max-width: 768px) {
    background: #efefef;
    padding: 10px;
  }
`

const StyledSection = styled.section`
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    background: #efefef;
  }
`

const StyledMapDescriptionWrapper = styled.div`
  position: absolute;
  left: 50px;
  top: 100px;
  z-index: 4;
  display: block;
  @media screen and (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
`
const StyledInnerWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const StyledMapContainer = styled.div`
  width: 100%;
`
